import { OpenInNew } from '@mui/icons-material';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface OpenProjectProps {
  AOI: any;
  variant?: 'IconOnly' | 'IconAndText';
}

export const OpenProjectComponent: React.FC<OpenProjectProps> = ({ AOI, variant }) => {
  const navigate = useNavigate();
  const handleOpen = () => {
    navigate('/survey-request', { state: { AOI } });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!variant) variant = 'IconAndText';

  return (
    <Button variant="contained" sx={{ backgroundColor: 'var(--gentian-tertiary)' }} onClick={handleOpen}>
      {!isMobile && variant === 'IconAndText' && ''} <OpenInNew />
    </Button>
  );
};
