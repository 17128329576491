import { createBrowserRouter } from 'react-router-dom';
import { SurveyRequestPage } from './Pages/SurveyRequest';
import { FindOutMorePage } from './Pages/FindOutMore';
import ErrorPage from './Pages/Error';
import { PrivacyPolicyPage } from './Pages/PrivacyPolicy';
import { TermsAndConditionsPage } from './Pages/TermsAndConditions';
import { DashboardPage } from './Pages/Dashboard';
import { SurveyResultPage } from './Pages/SurveyResults';
import { ResultUploadPage } from './Pages/ResultsUpload';
import { VerifyEmailPage } from './Pages/VerifyEmail';
import { ProductsPage } from './Pages/Products';
import { Res } from './resources';
import { ProjectsPage } from './Pages/Projects';

export const router = createBrowserRouter([
  {
    path: '/',
    element: Res.feature_flags.ENABLE_DASHBOARD ? <DashboardPage /> : <SurveyRequestPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/survey-request',
    element: <SurveyRequestPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/result-upload',
    element: Res.feature_flags.ENABLE_RESULT_UPLOAD ? <ResultUploadPage /> : <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/survey-result/:projectId',
    element: Res.feature_flags.ENABLE_VIEW_RESULTS ? <SurveyResultPage /> : <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/dashboard',
    element: Res.feature_flags.ENABLE_DASHBOARD ? <DashboardPage /> : <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/projects',
    element: Res.feature_flags.ENABLE_PROJECTS ? <ProjectsPage /> : <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/find-out-more/:productName',
    element: <FindOutMorePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicyPage />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditionsPage />,
  },
  {
    path: '/verify-email',
    element: <VerifyEmailPage />,
  },
  {
    path: '/products',
    element: Res.feature_flags.ENABLE_PRODUCT_MANAGEMENT ? <ProductsPage /> : <ErrorPage />,
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
]);
