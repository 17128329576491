import './style.scss';
import React, { useState } from 'react';
import { IProject } from '../../core/interfaces/project.interface';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DownloadAOIComponent } from '../DownloadAIO';
import { OpenAOIComponent } from '../OpenAOI';
import { Download, Edit, OpenInNew } from '@mui/icons-material';
import { ProjectUpdateModal } from '../ProjectUpdateModal';
import { DownloadProjectComponent } from '../DownloadProject';
import { OpenProjectComponent } from '../OpenProject';

export interface PendingProjectsCardsProps {
  projects: IProject[];
  onRowSelect?: (project: IProject) => void;
  onEditProject?: (project: IProject) => void;
}

export const PendingProjectsCardsComponent: React.FC<PendingProjectsCardsProps> = ({
  projects,
  onRowSelect,
  onEditProject,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [codeEditModalOpen, setCodeEditModalOpen] = useState(false);
  const [project, setProject] = useState<IProject | undefined>(undefined);

  return (
    <Box>
      {codeEditModalOpen && (
        <ProjectUpdateModal
          field="name"
          project={project}
          onClose={() => setCodeEditModalOpen(false)}
          onSaveClick={(value) => {
            if (project) {
              project.name = value;
              onEditProject?.(project);
              setCodeEditModalOpen(false);
            }
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'flex-start',
        }}
      >
        {projects.map((project) => (
          <Card
            key={project._id}
            sx={{
              width: 'calc(33% - 8px)',
              maxWidth: '300px',
              marginBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              padding: 1,
              borderRadius: '8px',
            }}
          >
            <CardHeader
              sx={{
                padding: '8px !important',
              }}
              title={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h6">{project.name}</Typography>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setProject(project);
                      setCodeEditModalOpen(true);
                    }}
                  >
                    <Tooltip title="Edit project name">
                      <Edit />
                    </Tooltip>
                  </IconButton>
                </Box>
              }
            />
            <CardMedia
              image={project.AOIThumbnail}
              title={project.name}
              component="img"
              sx={{
                height: 150,
                padding: '8px',
                display: 'block',
                margin: 'auto',
                width: '83%',
              }}
            />
            <CardActions sx={{ justifyContent: 'flex-end', marginTop: 'auto' }}>
              <Box>
                <DownloadProjectComponent AOI={project.AOI} />
              </Box>
              <Box>
                <OpenProjectComponent AOI={project.AOI} />
              </Box>
            </CardActions>
          </Card>
        ))}
      </Box>
    </Box>
  );
};
