// TODO: Split it into multiple hooks
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IProject } from '../interfaces/project.interface';
import { User, useAuth0 } from '@auth0/auth0-react';
import { Res } from '../../resources';
import { IAccountData } from '../interfaces/account-data.interface';
import { IResult } from '../interfaces/result.interface';
import { IProduct } from '../interfaces/product.interface';
import { datadogLogs } from '@datadog/browser-logs';
import { ISharedResults } from '../interfaces/shared-results.interface';

const useGentian = () => {
  const { isAuthenticated } = useAuth0();
  const queryClient = useQueryClient();

  const retrieveToken = async (source: string): Promise<string | null> => {
    datadogLogs.logger.info(`requesting token for ${source}`, { name: source, isAuthenticated });
    if (!isAuthenticated) {
      datadogLogs.logger.info('User is not authenticated. Ignoring query', {
        source,
        isAuthenticated,
      });
      return null;
    }

    const token = localStorage.getItem('gop-auth-token');
    if (!!token) return token;

    datadogLogs.logger.info('No token found in the local storage. Canceling queries and mutations.', {
      name: source,
    });

    await queryClient.cancelQueries();
    await queryClient.cancelMutations();

    return null;
  };

  const createProject = useMutation(async (projectData: IProject) => {
    const token = await retrieveToken('createProject');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(projectData),
    });

    if (!response.ok) {
      let text = '';
      let error: Error = null;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText} - ${text}`,
          {
            name: 'createProject',
            error: error,
            projectData,
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return response.json();
  });

  const updateProjectName = useMutation(async ({ projectId, name }: { projectId: string; name: string }) => {
    const token = await retrieveToken('updateProjectName');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project/${projectId}/name`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      let text = '';
      let error: Error = null;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText} - ${text}`,
          {
            name: 'updateProjectName',
            error: error,
            payload: { projectId, name },
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return true;
  });

  const updateResult = useMutation(async (resultData: IResult) => {
    const token = await retrieveToken('updateResult');
    if (!token) return;

    const response = await fetch(
      `${Res.api.gentian.BFF_BASE_URL}/results/${resultData.project._id}/additional-notes`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ additionalNotes: resultData.additionalNotes }),
      },
    );

    if (!response.ok) {
      let text = '';
      let error: Error = null;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText} - ${text}`,
          {
            name: 'updateResult',
            error: error,
            resultData,
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return response;
  });

  const updateProjectCode = useMutation(async ({ projectId, code }: { projectId: string; code: string }) => {
    const token = await retrieveToken('updateProjectCode');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project/${projectId}/code`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ code }),
    });

    if (!response.ok) {
      let text = '';
      let error: Error = null;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText} - ${text}`,
          {
            name: 'updateProjectCode',
            error: error,
            payload: { projectId, code },
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return true;
  });

  const createResult = useMutation(async (resultData: IResult) => {
    const token = await retrieveToken('createResult');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(resultData),
    });

    if (!response.ok) {
      let text = '';
      let error: Error = null;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText}`,
          {
            name: 'createResult',
            error: error,
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }

    return response.json();
  });

  const publishResult = useMutation(async (resultData: IResult) => {
    const token = await retrieveToken('publishResult');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/publish`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(resultData),
    });

    if (!response.ok) {
      let text = '';
      let error: Error = null;
      try {
        error = await response.json();
        text = error.message;
      } finally {
        datadogLogs.logger.error(
          `${response.status}: ${response.statusText}`,
          {
            name: 'publishResult',
            error: error,
            baseURL: Res.api.gentian.BFF_BASE_URL,
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          },
          error,
        );
        throw new Error(`${response.status}: ${response.statusText} - ${text}`);
      }
    }
  });

  const updateAccount = useMutation(async (userData: User) => {
    const token = await retrieveToken('updateAccount');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/account/${userData.sub}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to update user data', {
        name: 'updateAccount',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to update user data');
    }

    return response.json();
  });

  const getResultByProjectIdFunction = async (projectId: string, signal?) => {
    const token = await retrieveToken('getResultByProjectIdFunction');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/project/${projectId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      signal,
      cache: 'no-cache',
    });

    if (!response?.ok) {
      return undefined;
    }

    const responseJson = await response.json();
    return responseJson?.resultData as IResult | undefined;
  };

  const getResultByProjectId = (projectId: string) => {
    if (!projectId) return;
    return useQuery<unknown, unknown, IResult, any>(
      ['result_by_project_id', projectId],
      ({ signal }) => getResultByProjectIdFunction(projectId, signal),
      { refetchOnWindowFocus: false, refetchOnMount: false, cacheTime: 15 * 60 * 1000 },
    );
  };

  const getAccount = () => {
    return useQuery<unknown, unknown, IAccountData, any>(['account'], async ({ signal }) => {
      const token = await retrieveToken('getAccount');
      if (!token) return;

      const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/account/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        signal,
      });

      if (!response.ok) {
        datadogLogs.logger.error('Failed to fetch user data', {
          name: 'getAccount',
          response: {
            status: response.status,
            statusText: response.statusText,
          },
        });
        throw new Error('Failed to fetch user data');
      }

      return response.json();
    });
  };

  const getProjects = () => {
    return useQuery<unknown, unknown, IProject[], any>(
      ['projects'],
      async ({ signal }) => {
        const token = await retrieveToken('getProjects');
        if (!token) return;

        let response: Response;
        try {
          response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            signal,
          });
        } catch (error) {
          if ((error as Error).name === 'AbortError') return;
          throw error;
        }

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch user data', {
            name: 'getProjects',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to fetch user data');
        }

        return response.json();
      },
      {
        retryDelay: 5000,
        enabled: isAuthenticated,
      },
    );
  };

  const getSharedResults = (enabled = true) => {
    return useQuery<unknown, unknown, ISharedResults[], any>(
      ['shared-results'],
      async ({ signal }) => {
        const token = await retrieveToken('getSharedResults');
        if (!token) return;

        let response: Response;
        try {
          response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/shared/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            signal,
          });
        } catch (error) {
          if ((error as Error).name === 'AbortError') return;
          throw error;
        }

        if (!response?.ok) {
          datadogLogs.logger.error('Failed to fetch shared results', {
            name: 'getSharedResults',
            response: {
              status: response?.status,
              statusText: response?.statusText,
            },
          });
          throw new Error('Failed to fetch shared results');
        }

        return (await response?.json())?.resultData as ISharedResults[];
      },
      {
        cacheTime: 3600000,
        enabled: isAuthenticated && enabled,
        retry: 2,
        onError: (error) => console.warn(error),
        retryDelay: 5000,
      },
    );
  };

  const getProducts = () => {
    return useQuery<unknown, unknown, IProduct[], any>(
      ['all_products'],
      async ({ signal }) => {
        const token = await retrieveToken('getProducts');
        if (!token) return;

        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products/active`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          signal,
        });

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch products', {
            name: 'getProducts',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to fetch products');
        }

        return response.json();
      },
      { refetchOnWindowFocus: false, refetchOnMount: false, cacheTime: 3600000, enabled: isAuthenticated },
    );
  };

  const getAllProducts = () => {
    return useQuery<unknown, unknown, IProduct[], any>(
      ['all_products'],
      async ({ signal }) => {
        const token = await retrieveToken('getAllProducts');
        if (!token) return;

        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products/all`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          signal,
        });

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch products', {
            name: 'getAllProducts',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to fetch products');
        }

        return response.json();
      },
      { refetchOnWindowFocus: false, refetchOnMount: false, enabled: isAuthenticated },
    );
  };

  const getAllProjects = ({ status }: { status: 'has-result' | 'no-result' }) => {
    return useQuery<unknown, unknown, IProject[], string[]>(
      ['all_projects'],
      async ({ signal }) => {
        const token = await retrieveToken('getAllProjects');
        if (!token) return;

        const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/project/status/${status}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          signal,
        });

        if (!response.ok) {
          datadogLogs.logger.error('Failed to fetch user data', {
            name: 'getAllProjects',
            response: {
              status: response.status,
              statusText: response.statusText,
            },
          });
          throw new Error('Failed to fetch user data');
        }

        return response.json();
      },
      { refetchOnWindowFocus: false, refetchOnMount: false, enabled: isAuthenticated },
    );
  };

  const uploadFindOutMorePDF = useMutation(async ({ file, code }: { file: File; code: string }) => {
    const token = await retrieveToken('uploadFindOutMorePDF');
    if (!token) return;

    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products/${code}/find-out-more`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to upload file', {
        name: 'uploadFindOutMorePDF',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to upload file');
    }

    return (await response.json()) as { publicUrl: string };
  });

  const createProduct = useMutation(async (productData: IProduct) => {
    const token = await retrieveToken('createProduct');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to create product', {
        name: 'createProduct',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to create product');
    }

    return;
  });

  const updateProduct = useMutation(async (productData: IProduct) => {
    const token = await retrieveToken('updateProduct');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/products/${productData._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to update product', {
        name: 'updateProduct',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to update product');
    }

    return;
  });

  const addResult = useMutation(async (projectId: string) => {
    const token = await retrieveToken('addResult');
    if (!token) return;

    const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/shared/${projectId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ projectId }),
    });

    if (!response.ok) {
      datadogLogs.logger.error('Failed to add shared result', {
        name: 'addResult',
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      throw new Error('Failed to create product');
    }

    return;
  });

  const setResultsPermissions = useMutation(
    async ({ projectId, shareResultsArtifacts }: { projectId: string; shareResultsArtifacts: boolean }) => {
      const token = await retrieveToken('setResultsPermissions');
      if (!token) return;

      const response = await fetch(`${Res.api.gentian.BFF_BASE_URL}/results/permissions/${projectId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ shareResultsArtifacts }),
      });

      if (!response.ok) {
        let text = '';
        let error: Error = null;
        try {
          error = await response.json();
          text = error.message;
        } finally {
          datadogLogs.logger.error(
            `${response.status}: ${response.statusText} - ${text}`,
            {
              name: 'setSharingPermissions',
              error: error,
              payload: { projectId, shareResultsArtifacts },
              baseURL: Res.api.gentian.BFF_BASE_URL,
              response: {
                status: response.status,
                statusText: response.statusText,
              },
            },
            error,
          );
          throw new Error(`${response.status}: ${response.statusText} - ${text}`);
        }
      }

      return true;
    },
  );

  return {
    addResult,
    createProduct,
    createProject,
    createResult,
    getAccount,
    getAllProducts,
    getAllProjects,
    getProducts,
    getProjects,
    getResultByProjectId,
    getResultByProjectIdFunction,
    getSharedResults,
    publishResult,
    setResultsPermissions,
    updateAccount,
    updateProduct,
    updateProjectCode,
    updateProjectName,
    uploadFindOutMorePDF,
    updateResult,
  };
};

export default useGentian;
