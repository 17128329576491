import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { IProject } from '../../core/interfaces/project.interface';
import { IResult, IResultLayer } from '../../core/interfaces/result.interface';
import { LayerConfigComponent } from './LayerConfiguration';
import { Add, Delete, FilePresent } from '@mui/icons-material';
import useGentian from '../../core/hookies/gentian.hook';
import { useQuery } from 'react-query';
import { buildLayerConfig } from '../../core/builders/layer.builder';
import { LoadAOIComponent } from '../LoadAOI';
import * as hash from 'object-hash';

interface ResultFormProps {
  project: Pick<IProject, '_id' | 'name' | 'AOIThumbnail' | 'code' | 'deliverableUrl'>;
  onSave?: (result: IResult) => void;
  onPublish?: (result: IResult) => void;
  onUpdate?: (result: IResult) => void;
}

const CustomTabLabel = ({ label }) => <Box style={{ display: 'flex', alignItems: 'center' }}>{label}</Box>;

const ResultForm: React.FC<ResultFormProps> = ({ project, onSave, onUpdate, onPublish }) => {
  const now = new Date();
  const { user } = useAuth0();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);

  const [activeLayer, setActiveLayer] = useState<IResultLayer>(undefined);
  const [yearOfReference, setYearOfReference] = useState<number>(new Date().getFullYear());
  const [deliverableUrl, setDeliverableUrl] = useState<string>(project?.deliverableUrl || '');
  const [timeoutToken, setTimeoutToken] = useState<NodeJS.Timeout>();
  const [result, setResult] = useState<IResult>({
    project: { _id: project._id, name: project.name, AOIThumbnail: project.AOIThumbnail },
    draft: true,
    status: 'created',
    createdBy: user?.name || 'Unknown',
    createdAt: now,
    lastModifiedAt: now,
    yearOfReference: now.getFullYear(),
    layers: [],
  });

  const { getResultByProjectIdFunction } = useGentian();

  const {
    data: existingResult,
    isLoading,
    isFetched,
    isError,
  } = useQuery(['result_by_project_id', project], () => getResultByProjectIdFunction(project._id), {
    enabled: !!project,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 0,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setActiveLayer(undefined);
    if (!!result?.layers[newValue]) {
      setTimeout(() => setActiveLayer(result.layers[newValue]), 0);
    }
  };

  const onLoadResult = (geojson: GeoJSON.FeatureCollection | GeoJSON.Feature, fileName: string) => {
    if (!result) {
      return;
    }

    setIsLoadingFiles(true);

    const hashKey = hash({ data: geojson, fileName });

    if (result?.shapes?.find((s) => s.key === hashKey)) {
      // File already loaded
      setIsLoadingFiles(false);
      return;
    }

    if (!result?.shapes?.length) {
      result.shapes = [];
    }

    result.shapes.push({ data: geojson as any, fileName, key: hashKey });
    setResult({ ...result });
    setIsLoadingFiles(false);
  };

  useEffect(() => {
    if (timeoutToken) clearTimeout(timeoutToken);

    const token = setTimeout(() => {
      onUpdate?.(result);
      setTimeoutToken(undefined);
    }, 300);

    setTimeoutToken(token);
  }, [result]);

  useEffect(() => {
    if (existingResult) {
      setResult(existingResult);
      setActiveLayer(existingResult.layers[0]);
    } else {
      const now = new Date();
      setResult({
        project: {
          _id: project._id,
          name: project.name,
          AOIThumbnail: project.AOIThumbnail,
          code: project.code,
        },
        draft: true,
        status: 'created',
        createdBy: user?.name || 'Unknown',
        createdAt: now,
        lastModifiedAt: now,
        yearOfReference: now.getFullYear(),
        deliverableUrl: project.deliverableUrl,
        layers: [],
      });
      setActiveLayer(undefined);
    }
  }, [isError, isFetched]);

  const handleLayerEdit = (index: number, layer: IResultLayer) => {
    result.layers[index] = layer;
    setResult({ ...result });
  };

  const handleSave = () => {
    onSave(result);
  };

  const removeLayer = (index) => {
    result.layers.splice(index, 1);
    setResult({ ...result });

    handleTabChange(null, index ? index - 1 : 0);
  };

  return isLoading ? (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="body1">Checking for existing results...</Typography>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        div: { minHeight: '42px', fontSize: '12px' },
        label: { fontSize: '12px' },
        '.MuiTabs-vertical': { width: '100px' },
      }}
    >
      {!!project && (
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Project name: {project.name}
          </Typography>
          <TextField
            label="Image from year"
            type="number"
            value={yearOfReference}
            onChange={(e) => setYearOfReference(parseInt(e.target.value))}
            onBlur={() => setResult({ ...result, yearOfReference })}
            sx={{ mb: 1 }}
          />
          <TextField
            label="Deliverable URL"
            value={deliverableUrl}
            onChange={(e) => setDeliverableUrl(e.target.value)}
            onBlur={() => setResult({ ...result, deliverableUrl })}
            sx={{ mb: 1 }}
          />
          <Box sx={{ lineHeight: '56px', display: 'flex', my: 2, flexDirection: 'column' }}>
            <LoadAOIComponent
              onLoadAOI={onLoadResult}
              onError={(error) => console.error('Error loading AOI:', error)}
            />
            {isLoadingFiles ? (
              <>
                <Typography variant="body2">Adding file to the result...</Typography>
                <LinearProgress sx={{ my: 2, width: '100%' }} variant="indeterminate" />
              </>
            ) : !!result?.shapes?.length ? (
              <>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Loaded files:
                </Typography>
                {result?.shapes?.map((shape) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        ml: 1,
                        color: 'gray',
                        display: 'flex',
                        alignItems: 'center',
                        textOverflow: 'ellipsis',
                      }}
                      key={`loaded_file_${shape.key}`}
                      id={`loaded_file_${shape.key}`}
                    >
                      <FilePresent sx={{ mr: 1 }} />
                      {shape.fileName}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        const index = result.shapes.findIndex((s) => s.key === shape.key);
                        result.shapes.splice(index, 1);
                        setResult({ ...result });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                ))}
              </>
            ) : (
              <Typography
                variant="body2"
                sx={{ ml: 1, color: 'gray' }}
                key="no_file_loaded_text"
                id="no_file_loaded_text"
              >
                No files loaded
              </Typography>
            )}
          </Box>
          {result?.status !== 'created' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mb: 2,
                minHeight: '24px !important',
              }}
            >
              <Typography variant="body1">Result status: </Typography>
              <Box
                sx={{
                  bgcolor: result.status === 'published' ? '#90e394' : '#ffe682',
                  p: '2px 8px 2px 8px',
                  borderRadius: '16px',
                  width: 'fit-content',
                  height: '24px',
                  minHeight: '24px !important',
                  fontSize: '12px',
                  ml: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {result?.status === 'published' ? 'Published' : 'Draft'}
              </Box>
            </Box>
          )}
          <Divider sx={{ mb: 1 }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body1">Layers configuration</Typography>
            <IconButton
              onClick={() => {
                const index = (result?.layers?.length || 0) + 1;
                const layer = buildLayerConfig('result', index);
                result?.layers.push(layer);
                setResult({ ...result });
                setTimeout(() => handleTabChange(null, index - 1), 0);
              }}
            >
              <Add />
            </IconButton>
          </Box>
          <Box>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="layers tabs"
              variant="scrollable"
            >
              {result?.layers?.map((layer, index) => (
                <Tab
                  key={`${layer.resultId}_${index}`}
                  label={<CustomTabLabel label={layer.displayName} />}
                />
              ))}
            </Tabs>
            {activeLayer && (
              <LayerConfigComponent
                layer={activeLayer}
                index={selectedTab}
                first={selectedTab === 0}
                last={selectedTab === result.layers.length - 1}
                shapes={result.shapes}
                onRemoveLayer={(index) => removeLayer(index)}
                onMoveLayer={(index, direction) => {
                  const newIndex = index + direction;
                  if (newIndex >= 0 && newIndex < result.layers.length) {
                    const temp = result.layers[selectedTab];
                    result.layers[selectedTab] = result.layers[newIndex];
                    result.layers[newIndex] = temp;
                    setResult({ ...result });
                    setTimeout(() => handleTabChange(null, newIndex), 0);
                  }
                }}
                onLayerEdit={(index, layer) => {
                  handleLayerEdit(index, layer);
                }}
              />
            )}
          </Box>

          {/* Save Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ mt: 2 }}
            disabled={!activeLayer?.details?.columnName && !activeLayer?.imageryUri}
          >
            {result?.draft ? 'Save Draft' : 'Save Changes'}
          </Button>

          {result.status !== 'published' && activeLayer?.details?.columnName && (
            <Typography variant="body2" sx={{ mt: 3 }}>
              This result is a draft, it will not be visible to other users until it is published.
            </Typography>
          )}

          {result?.status === 'on_going' && (
            <Button
              variant="contained"
              sx={{ mt: 2, backgroundColor: 'var(--gentian-tertiary)', width: '100%' }}
              onClick={() => onPublish(result)}
            >
              Publish
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default ResultForm;
